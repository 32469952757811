.btn {
  border: none;
  font-family: inherit;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  background-color: transparent;
  text-decoration: none;

  &--blue {
    background-color: var(--color-primary);
    color: var(--color-grey-light-1);
    padding: 1rem 2rem;
    box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.15);

    &:hover {
      background-color: var(--color-primary-darken);
      transform: translateY(-0.2rem);
      box-shadow: 0 1.2rem 3.6rem rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: translateY(-0.1rem);
    }
  }
}

.btn-view {
  border: 1px solid var(--color-primary);
  padding: 1rem 2rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: none;
  height: max-content;
  flex: 1;

  &--active {
    background-color: var(--color-primary);

    span {
      color: var(--color-grey-light-1);
    }
    font-weight: 500;

    .btn-view__icon {
      fill: var(--color-grey-light-1);
    }
  }

  &__icon {
    height: 2rem;
    width: 2rem;
    fill: var(--color-primary);
  }
}
