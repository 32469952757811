@use "../abstracts/mixins" as *;

.book-table {
  border-collapse: collapse;
  min-width: 130rem;

  thead {
    background-color: var(--color-primary);
    color: var(--color-grey-light-1);
    font-size: 1.6rem;
    font-weight: 500;
    position: sticky;
    top: 0;
  }

  th {
    padding: 2rem 1rem;
  }

  tr {
    text-align: center;
  }
  tbody {
    tr:nth-of-type(even) {
      background-color: var(--color-grey-light-1);
    }

    tr:nth-of-type(odd) {
      background-color: #e9e9e9;
    }
  }

  td {
    padding: 1rem;
    // width: min-content;
  }
}

.book-table__wrapper {
  width: 100%;
  overflow-y: auto;
  border: 1px solid var(--color-primary);

  @include respond(1000) {
    max-height: 80vh;
  }
}
