@use "../abstracts/mixins" as *;

.book-card {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
  padding: 2rem;
  gap: 2rem;
  position: relative;

  @include respond(600) {
    max-width: 35rem;
  }

  &__rating {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.6rem;
    width: 4.8rem;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
    background-color: var(--color-primary);
    color: var(--color-grey-light-1);
    font-weight: 700;
    border-radius: 3px;
  }

  & img {
    height: 30rem;
    // width: max-content;
    // object-fit: contain;
    border-radius: var(--border-radius);

    align-self: center;
    box-shadow: 0 1rem 2.4rem rgba(0, 0, 0, 0.25);
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    height: 100%;
  }

  & header {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  &__title {
    font-size: 2rem;
  }

  &__author {
    font-weight: 400;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  }

  &__detail {
    text-transform: capitalize;
    display: flex;
    gap: 1.4rem;
    align-items: center;
  }

  &__icon {
    height: 1.8rem;
    width: 1.8rem;
    fill: var(--color-primary);
  }
  & .btn {
    justify-self: center;
    text-transform: uppercase;
  }
}
