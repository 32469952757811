.ratings-graph__wrapper {
  height: 50rem;
  width: 100%;
  max-width: 100rem;
  // padding: 0 15rem;
  background-color: var(--color-grey-light-1);
  border-radius: var(--border-radius);
  padding: 1.6rem 2.4rem 1.2rem 2.4rem;
  border: 1px solid var(--color-primary);
  position: relative;
  margin-bottom: 4rem;

  &::after {
    content: "";
    background-color: var(--color-primary);
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    transform: translate(2rem, 2rem);
    border-radius: var(--border-radius);
  }
}
