@use "../abstracts/mixins" as *;

.book-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: max-content;
  gap: 4rem;

  @include respond(1200) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond(1000) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(600) {
    grid-template-columns: minmax(35rem, 1fr);
  }
}

.books-controls-wrapper {
  display: flex;
  gap: 2rem;

  @include respond(600) {
    display: grid;
    grid-template-rows: 1fr max-content;
    row-gap: 3rem;
    justify-items: center;
  }
}

.books-buttons-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: stretch;

  @include respond(600) {
    flex-direction: row;
    height: 4rem;
    align-content: stretch;
  }
}

.select-wrapper {
  align-self: center;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-content: center;
  gap: 2rem;
  font-size: 2rem;

  label {
    align-self: center;
  }
}

.select {
  align-self: center;
  background-color: var(--color-grey-light-1);
  width: 15vw;
  min-width: 20rem;
  font-family: inherit;
  font-size: 1.6rem;
  padding: 1rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-primary);

  option:hover {
    background-color: purple;
  }

  &:focus {
    outline: none;
  }
}
