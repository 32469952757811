@use "../abstracts/mixins" as *;

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 8;
}

.modal {
  position: fixed;
  display: flex;
  background-color: var(--color-grey-light-1);
  width: 60vw;
  max-width: 100rem;
  min-width: 85rem;
  max-height: 90vh;
  height: max-content;
  z-index: 100;
  border-radius: var(--border-radius);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  gap: 4rem;
  overflow-y: auto;

  @include respond(800) {
    flex-direction: column;
    max-width: auto;
    min-width: auto;
    width: 80vw;
  }

  & img {
    height: 40rem;
    box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.2);
    border-radius: var(--border-radius);
    object-fit: contain;
    width: max-content;
    align-self: center;

    @include respond(800) {
      height: 30rem;
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  & header {
    margin-bottom: 2rem;
    margin-right: 3rem;
  }

  &__title {
    font-size: 4rem;
  }

  &__author {
    font-size: 2.4rem;
    font-weight: 500;
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 2.4rem;
    flex: 1;
    align-items: space-between;

    @include respond(800) {
      grid-template-columns: 65% 35%;
    }
  }

  &__stat {
    display: flex;
    gap: 1.4rem;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 300;
  }

  &__icon {
    height: 3rem;
    width: 3rem;
    fill: var(--color-primary);
  }

  &__letter-icon {
    background-color: var(--color-primary);
    color: var(--color-grey-light-1);
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-weight: 500;
    border-radius: 1000rem;
  }

  &__ratings {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
  }

  &__btn-close {
    font-size: 6rem;
    position: absolute;
    right: 2rem;
    top: 0;
  }
}
