@use "../abstracts/mixins" as *;

.slider {
  background-color: #f7f7f7;
  height: 45rem;
  max-width: 80rem;
  width: 90vw;
  border-radius: var(--border-radius);
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  padding: 2rem;
  border: 1px solid var(--color-primary);

  @include respond(600) {
    height: 70rem;
  }

  &__content-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }
  &::after {
    content: "";
    background-color: var(--color-primary);
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    transform: translate(2rem, 2rem);
    border-radius: var(--border-radius);
  }

  &__btn {
    height: 5rem;
    width: 4.6rem;
    background-color: var(--color-primary);
    color: var(--color-grey-light-1);
    margin-bottom: 2rem;
    z-index: 10;

    &:hover {
      background-color: var(--color-primary-darken);
    }

    &--prev {
      left: 2.4rem;
      grid-column: 1;
    }

    &--next {
      right: 2.4rem;
      grid-column: 3;
    }
  }

  &__btn-icon {
    height: 2rem;
    width: 2rem;
  }
}

.slide {
  display: grid;
  grid-template-columns: 1fr 45%;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 2rem 3rem;
  opacity: 0;
  transition: all 0.3s linear;
  column-gap: 2rem;
  align-items: center;

  grid-template-rows: max-content 1fr;

  @include respond(600) {
    grid-template-columns: 100%;
    padding: 2rem 1.4rem;
    row-gap: 2rem;
    grid-template-rows: max-content max-content 1fr;
  }

  &--prev {
    transform: translateX(-100%);
  }

  &--active {
    opacity: 1;
    transform: translateX(0);
  }

  &--next {
    transform: translateX(100%);
  }

  & header {
    grid-column: span 2;
    height: max-content;

    @include respond(600) {
      grid-column: span 1;
    }
  }

  &__chart-wrapper {
    max-height: 35rem;
    height: 90%;
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    width: 100%;
  }

  &__ratings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__name {
    font-size: 3.6rem;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;

    &::after {
      content: "";
      margin-top: 0.4rem;
      background-color: var(--color-primary);
      height: 0.6rem;
      width: 100%;
      display: block;
    }
  }

  &__rating-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
