@use "../abstracts/mixins" as *;

*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;

  @include respond(1400) {
    font-size: 56.25%;
  }

  @include respond(800) {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
}
