.header {
  background-color: var(--color-primary);
  position: sticky;
  top: 0;
  z-index: 7;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.25);
}

.nav {
  &__list {
    display: flex;
    list-style: none;
    justify-content: center;
    gap: 5rem;
  }

  &__list-item {
    padding: 1.8rem 0 2.4rem 0;
  }

  &__btn {
    font-family: inherit;
    color: var(--color-grey-light-1);
    text-decoration: none;
    font-size: 2.4rem;
    position: relative;

    &--active::after {
      content: "";
      position: absolute;
      height: 0.2rem;
      width: 100%;
      background-color: var(--color-secondary);
      left: 0;
      bottom: -0.4rem;
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  &__btn:hover::before {
    opacity: 1;
  }

  &__btn::before {
    content: "";
    position: absolute;
    height: 0.2rem;
    width: 100%;
    background-color: var(--color-secondary);
    bottom: -0.4rem;
    opacity: 0;
    transition: opacity 0.3s;
  }
}
