// MEDIA QUERY MANAGER

@mixin respond($breakpoint) {
  @if $breakpoint == 600 {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == 800 {
    @media only screen and (max-width: 50em) {
      @content;
    }
  }
  @if $breakpoint == 900 {
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == 1000 {
    @media only screen and (max-width: 62.5em) {
      @content;
    }
  }
  @if $breakpoint == 1200 {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == 1400 {
    @media only screen and (max-width: 87.5em) {
      @content;
    }
  }
}
