body {
  background-color: var(--color-secondary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.heading-primary {
  font-size: 4.8rem;
  margin-bottom: 7.2rem;
  position: relative;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    height: 0.6rem;
    background-color: var(--color-primary);
    width: 100%;
    bottom: -0.6rem;
  }
}

.heading-error {
  font-size: 3.6rem;
  margin-bottom: 4rem;
}

.container {
  max-width: 140rem;
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__body {
    gap: 6rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0 2.4rem;
  }
}
